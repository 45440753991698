jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger,.close-menu').click(function(){
    $('header .wrapper').toggleClass('active')
    $('.menu-trigger').toggleClass('active')
  })

  // popup
  $('.popup-trigger').click(function(e){
    e.preventDefault()
    const targetPopup = $(this).attr('href')
    $(`.popup#${targetPopup}`).addClass('active')
  })

  $('.popup-close').click(function(){
    $('.popup').removeClass('active')
  })

  $('.popup#video .popup-close').click(function(){
    $('#trailer').get(0).pause()
  })


});